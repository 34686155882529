<template>
  <a-modal :title="modal.title" v-model="modal.visible" @ok="handleOk" @cancel="handleCancel">
    <div>
      <div v-for="(item, index) in modal.data.fileList" :key="index">
        <a @click="openFile(item)">{{ item.name }}</a>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { toolMixin } from '@/utils/mixin'

export default {
  data() {
    return {
      modal: {
        visible: false,
        title: '查看文档',
        data: {
          fileList: [],
        },
      },
    }
  },
  mixins: [toolMixin],
  methods: {
    start(fileList) {
      this.resetModal()
      this.modal.data.fileList = fileList
      this.modal.visible = true
      return new Promise((resolve, reject) => {
        this.$once('end', function (state, result) {
          if (state === 'ok') {
            resolve(result)
          }
          if (state === 'cancel') {
            reject(new Error('E_CANCEL'))
          }
          this.modal.visible = false
        })
      })
    },
    openFile(file) {
      const token = this.$store.state.user.auth.accessToken
      const url = file.url + `&token=${token}`
      window.open(url)
    },
    async handleOk() {
      this.$emit('end', 'ok', {})
    },
    handleCancel() {
      this.$emit('end', 'cancel')
    },
  },
  mounted() {},
}
</script>

<style></style>
